import { Address } from '@Types/account/Address'
import { Cart } from '@Types/cart/Cart'
import { Discount } from '@Types/cart/Discount'
import { Payment } from '@Types/cart/Payment'
import { Variant } from '@Types/product/Variant'
import useSWR, { mutate } from 'swr'
import { PatientDetailsProps, PrescriptionIDB } from 'types/checkout'
import { fetchApiHub, revalidateOptions } from 'frontastic'
import { CartItemError } from '../../../nerivio-ui/cart/errors/CartItemError'
import { PaymentResponseError } from '../../../nerivio-ui/checkout/errors/PaymentResponseError'
import { PaymentHandler } from '../../../nerivio-ui/checkout/utils/PaymentHandler'
import { DiscountCode } from '@Types/voucherify/Voucher'
import { CheckoutValidationError } from '../../../nerivio-ui/checkout/errors/CheckoutValidationError'

export type CartDetails = {
  account?: { email: string }
  shipping?: Address
  billing?: Address
}

export const cartItems = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useSWR('/action/cart/getCart', fetchApiHub, revalidateOptions)
}

export const checkout = async (prescription: PrescriptionIDB) => {
  const res = await fetchApiHub(
    '/action/cart/checkout',
    {
      method: 'POST',
    },
    prescription,
  )
  mutate('/action/cart/getCart', res)

  return res
}
export const unsetCartSession = async () => {
  const res = await fetchApiHub('/action/cart/unsetCartSession', {
    method: 'POST',
  })
  mutate('/action/cart/getCart', res)
  // return await fetchApiHub('/action/cart/unsetCartSession');
}
export const orderHistory = async () => {
  return await fetchApiHub('/action/cart/getOrders')
}

export const getOrder = async (orderNumber: string) => {
  return await fetchApiHub(`/action/cart/getOrder?orderNumber=${orderNumber}`)
}

export const getProjectSettings = async () => {
  return await fetchApiHub('/action/project/getProjectSettings')
}

export const getShippingSettings = async () => {
  return await fetchApiHub('/action/project/getShippingSettings')
}

export const getShippingMethods = async () => {
  return await fetchApiHub('/action/cart/getShippingMethods')
}

export const getAvailableShippingMethods = async () => {
  return await fetchApiHub('/action/cart/getAvailableShippingMethods')
}

export const addItem = async (variant: Variant, quantity: number) => {
  const payload = {
    variant: {
      sku: variant.sku,
      count: quantity,
    },
  }
  const res = await fetchApiHub(
    '/action/cart/addToCart',
    {
      method: 'POST',
    },
    payload,
  )
  await updateCart(res)
  await mutate('/action/cart/getCart', res, { revalidate: true })
}

export const removeItem = async (lineItemId: string) => {
  const payload = {
    lineItem: { id: lineItemId },
  }

  const res = await fetchApiHub(
    '/action/cart/removeLineItem',
    {
      method: 'POST',
    },
    payload,
  )
  await updateCart(res)
  await mutate('/action/cart/getCart', res, { revalidate: true })
}

export const updateItem = async (lineItemId: string, newQuantity: number) => {
  const payload = {
    lineItem: {
      id: lineItemId,
      count: newQuantity,
    },
  }
  const res = await fetchApiHub(
    '/action/cart/updateLineItem',
    {
      method: 'POST',
    },
    payload,
  )
  await updateCart(res)
  await mutate('/action/cart/getCart', res, { revalidate: true })
}

export const updateCart = async (payload: CartDetails): Promise<Cart> => {
  const res = await fetchApiHub(
    '/action/cart/updateCart',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )
  await mutate('/action/cart/getCart', res, { revalidate: false })
  return res
}

export const refreshCart = async (): Promise<Cart> => {
  const res = await fetchApiHub('/action/cart/refreshCart', {
    headers: {
      accept: 'application/json',
    },
    credentials: 'include',
    method: 'GET',
  })

  mutate('/action/cart/getCart', res, { revalidate: false })

  return res
}

export const setShippingMethod = async (shippingMethodId: string) => {
  const payload = {
    shippingMethod: {
      id: shippingMethodId,
    },
  }

  const res = await fetchApiHub(
    `/action/cart/setShippingMethod?shippingMethodId=${shippingMethodId}`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/getCart', res, { revalidate: false })
}

export const redeemDiscountCode = async (code: string) => {
  const payload = {
    code: code,
  }
  const res = await fetchApiHub(
    `/action/cart/redeemDiscount`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )
  await updateCart(res)
  await mutate('/action/cart/getCart', res, { revalidate: true })
}

export const removeDiscountCode = async (discount: Discount) => {
  const payload = {
    discountId: discount.discountId,
  }
  const res = await fetchApiHub(
    '/action/cart/removeDiscount',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )
  await updateCart(res)
  await mutate('/action/cart/getCart', res, { revalidate: true })
}

export const addCoupon = async (discountCode: DiscountCode) => {
  const payload = {
    discountCode: discountCode.code,
  }
  const res = await fetchApiHub(
    '/action/cart/addCoupon',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )
  await mutate('/action/cart/getCart', res, { revalidate: true })
}

export const removeCounpon = async (index: number) => {
  const payload = { index }
  const res = await fetchApiHub(
    '/action/cart/removeCounpon',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )
  await mutate('/action/cart/getCart', res, { revalidate: true })
}

export const updatePatientDetails = async (cart: Cart, patientData: PatientDetailsProps) => {
  const isForSomeone = patientData.forSomeone

  const payload = {
    isForSomeone: isForSomeone,
    patientFirstName: isForSomeone ? patientData.patientFirstName : null,
    patientLastName: isForSomeone ? patientData.patientLastName : null,
    patientPhone: isForSomeone ? `(${patientData.patientCountryPhoneCode}) ${patientData.patientPhone}` : null,
  }

  /**
   * Be aware: if NOT order for someone else and the custom fields ARE NOT AVAILABLE,
   * DO NOT update cart
   */
  if (isForSomeone || (!isForSomeone && cart?.custom?.fields && 'PatientFirstName' in cart?.custom?.fields)) {
    const res = await fetchApiHub(
      '/action/cart/updatePatientDetails',
      {
        headers: {
          accept: 'application/json',
        },
        credentials: 'include',
        method: 'POST',
      },
      payload,
    )
    mutate('/action/cart/getCart', res, { revalidate: false })
  }
}

export const createMollieCustomer = async (payload: any) => {
  const createCustomer = await fetchApiHub(
    '/action/payment/createMollieCustomer',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )

  return createCustomer
}

export const initSubscriptionPayment = async (payload: any) => {
  const initSubscription = await fetchApiHub(
    '/action/payment/initSubscriptionPayment',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )

  return initSubscription
}

export const createSubscription = async (payload: any) => {
  const subscription = await fetchApiHub(
    '/action/payment/createSubscription',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )

  return subscription
}

export const getPvsUserMetaData = async (payload: any) => {
  try {
    const user = await fetchApiHub(
      '/action/payment/getPvsUserMetaData',
      {
        headers: {
          accept: 'application/json',
        },
        credentials: 'include',
        method: 'POST',
      },
      payload,
    )

    return user
  } catch (error) {
    return {}
  }
}

export const getPaymentMethods = async () => {
  const paymentMethods = await fetchApiHub('/action/payment/getPaymentMethods')
  // const cart = await fetchApiHub('/action/cart/getCart')

  // mutate('/action/cart/getCart', cart, { revalidate: false })

  return paymentMethods
}

export const setPaymentMethod = async (paymentMethodId: string) => {
  const payload = {
    paymentMethod: {
      id: paymentMethodId,
    },
  }

  const response = await fetchApiHub(
    '/action/payment/setPaymentMethod',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/getCart', response, { revalidate: false })
}

export const triggerPayment = async (): Promise<Payment> => {
  const response = await fetchApiHub('/action/payment/initCartPayment', {
    headers: {
      accept: 'application/json',
    },
    credentials: 'include',
    method: 'POST',
  })

  if (response?.statusCode && +response.statusCode > 299) {
    const message = response.message || 'unknown error'
    if (response.items) {
      throw new CartItemError(message, response.statusCode, response.items)
    }
    throw new PaymentResponseError(message, response.statusCode, response.field)
  }

  mutate('/action/cart/getCart', response, { revalidate: false })

  const payment = PaymentHandler.getMolliePayment(response)

  if (!payment) {
    throw new PaymentResponseError('payment synchronization error', 500)
  }

  return payment
}

export const syncCartPayment = async () => {
  const response = await fetchApiHub('/action/payment/syncCartPayment', {
    headers: {
      accept: 'application/json',
    },
    credentials: 'include',
    method: 'POST',
  })

  if (response?.statusCode && +response.statusCode > 299) {
    throw new PaymentResponseError(response.message || 'payment synchronization error', response.statusCode)
  }

  mutate('/action/cart/getCart', response, { revalidate: false })
}

export const getVoucherName = async () => {
  return await fetchApiHub('/action/cart/getVoucherName')
}

export const updateBillingAddress = async (billing: Address) => {
  const res = await fetchApiHub(
    '/action/cart/updateBillingAddress',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    {
      billing: billing,
    },
  )

  mutate('/action/cart/getCart', res, { revalidate: false })

  return res
}

export const uploadPrescription = async (prescriptionName: string, prescriptionContent: string) => {
  const response = await fetchApiHub(
    '/action/cart/uploadPrescription',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    {
      prescriptionName,
      prescriptionContent,
    },
  )
  if (+response?.statusCode === 400 || +response?.statusCode === 500 || response?.message) {
    throw new CheckoutValidationError(response.message, response.statusCode)
  }

  mutate('/action/cart/getCart', response, { revalidate: false })

  return response
}

export const validateToken = async (token: string) => {
  const response = await fetchApiHub(
    '/action/cart/verifyToken',
    {
      method: 'POST',
    },
    {
      token: token,
    },
  )
  return response
}

export const setCustomProductType = async (custom?: string) => {
  const response = await fetchApiHub(
    '/action/cart/setProductTypeOnCart',
    {
      method: 'POST',
    },
    {
      custom: custom,
    },
  )

  if (+response?.statusCode === 400 || +response?.statusCode === 500 || response?.message) {
    throw new Error(response.message, response.statusCode)
  }

  mutate('/action/cart/getCart', response, { revalidate: false })

  return response
}
