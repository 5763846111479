import { ParsedUrlQuery } from 'querystring'
import { Cart } from '@Types/cart/Cart'
import { Payment } from '@Types/cart/Payment'
import { CheckoutResponseError } from '../errors/CheckoutResponseError'
import { PaymentResponseError } from '../errors/PaymentResponseError'
import { isMollieUrl } from '../validation'

export class PaymentHandler {
  public static readonly paymentProviderId = 'Mollie'
  public static readonly defaultErrorCode = 422
  public static readonly defaultErrorMessage = 'Unknown error'

  public static readonly pendingStatus = ['init', 'pending']
  public static readonly settledStatus = ['paid', 'done']
  public static readonly unresolvedStatus = ['unresolved']
  public static readonly failedStatus = ['failed']

  public static readonly nonInteractiveTypes = ['banktransfer']

  private static readonly mollieRedirectParameter = { name: 'step', value: 'payment' }

  static getMolliePayment(cart: Cart): Payment {
    return cart.payments?.find(
      (payment) =>
        PaymentHandler.isMolliePayment(payment) &&
        !PaymentHandler.isPaymentFailed(payment) &&
        !PaymentHandler.isPaymentUnresolved(payment),
    )
  }

  static isMolliePayment(payment: Payment): boolean {
    return payment.paymentProvider === PaymentHandler.paymentProviderId
  }

  static isRedirectFromMollie(query: ParsedUrlQuery): boolean {
    return query[PaymentHandler.mollieRedirectParameter.name] === PaymentHandler.mollieRedirectParameter.value
  }

  static isPaymentPending(payment: Payment, strict = false): boolean {
    const pendingStatus = !!strict
      ? PaymentHandler.pendingStatus.filter((status) => status !== 'init')
      : PaymentHandler.pendingStatus
    return pendingStatus.includes(payment.paymentStatus)
  }

  static isPaymentSettled(payment: Payment): boolean {
    return PaymentHandler.settledStatus.includes(payment.paymentStatus)
  }

  static isTransactionSettled(payment: Payment): boolean {
    return payment.transactions.find(transaction => transaction.transactionState === "Success")
  }

  static isPaymentFailed(payment: Payment): boolean {
    return PaymentHandler.failedStatus.includes(payment.paymentStatus)
  }

  static isPaymentUnresolved(payment: Payment): boolean {
    return PaymentHandler.unresolvedStatus.includes(payment.paymentStatus)
  }

  static isMollieInteractionRequired(payment: Payment): boolean {
    return !PaymentHandler.isNonInteractiveType(payment.paymentMethod)
  }

  static isNonInteractiveType(type: any): boolean {
    return !!type && PaymentHandler.nonInteractiveTypes.includes(type)
  }

  static getCheckoutUrl(payment: Payment): string | null {
    // Special case - non-interaction payments like bank transfers do not need a redirect to mollie
    return PaymentHandler.isMollieInteractionRequired(payment)
      ? PaymentHandler.getMollieOrderUrl(payment)
      : '/order-summary'
  }

  static getMollieOrderUrl(payment: Payment): string | null {
    const mollieOrderUrl =
      payment.paymentDetails?.find((details) => details.id && details.id === 'mollieCheckoutUrl')?.value || null

    return isMollieUrl(mollieOrderUrl) ? mollieOrderUrl : null
  }

  static getCheckoutResponseError(error: any): CheckoutResponseError {
    if (error instanceof CheckoutResponseError) {
      return error
    }

    const message = error instanceof Error ? error.message : PaymentHandler.defaultErrorMessage

    return new PaymentResponseError(message, PaymentHandler.defaultErrorCode)
  }

  static getPendingOrderAmount(cart: Cart): number {
    const orderAmount = cart.sum?.centAmount || 0
    const paidAmount =
      cart?.payments
        ?.filter((payment) => PaymentHandler.isMolliePayment(payment) && PaymentHandler.isPaymentSettled(payment))
        ?.reduce((amount, payment) => {
          return amount + (payment.amountPlanned?.centAmount || 0)
        }, 0) || 0

    return Math.max(0, orderAmount - paidAmount)
  }

  static cartRequiresPayment(cart: Cart): boolean {
    if (!cart.lineItems || cart.lineItems.length === 0) {
      return false
    }

    return PaymentHandler.getPendingOrderAmount(cart) >= 0
  }

  static isSubscriptionPaid = (payments: Payment[]) => {
    return payments.filter(payment => {
      if (payment.amountPlanned.centAmount === 0) {
        return payment.transactions.filter(transaction => transaction.transactionState === 'Success')?.length > 0
      }
    })?.length > 0
  }
}
