import { VoucherListResponse, VoucherRedeemResponse, VoucherValidationResponse, VoucherFilters } from '@Types/voucherify/Voucher';
import { fetchApiHub } from "frontastic/lib/fetch-api-hub";

export const getVouchers = async (filters?: VoucherFilters): Promise<VoucherListResponse> => {
  const res = await fetchApiHub('/action/voucherify/getVouchers', { method: 'POST' }, filters)
  return res
};

export const redeemVoucher = async (codes: string[], accountId: string, orderId: string, SKUs: string[]): Promise<VoucherRedeemResponse> => {
  const res = await fetchApiHub('/action/voucherify/redeemVoucher', { method: 'POST' }, { codes, accountId, orderId, SKUs })
  return res
}

export const validateVoucher = async (code: string, accountId: string): Promise<VoucherValidationResponse> => {
  const res = await fetchApiHub('/action/voucherify/validateVoucher', { method: 'POST' }, { code, accountId })
  return res
}

export const markCustomerAsPaid = async (accountId: string, orderId: string): Promise<void> => {
  const res = await fetchApiHub('/action/voucherify/markCustomerAsPaid', { method: 'POST' }, { accountId, orderId })
  return res
}
